<script setup>
import {useForm, router} from "@inertiajs/vue3";
import {vOnClickOutside} from "@vueuse/components";
import {ref, watch} from "vue";
import VueFlatpickr from "./VueFlatpickr.vue";
import SearchIcon from "@/Icons/SearchIcon.vue";
import {CalendarDaysIcon, MapPinIcon} from "@heroicons/vue/24/outline";
import axios from "axios";
import {__} from "@/Composables/translate";
import {useSearchStore} from "@/stores/searchStore";


const props = defineProps({
    recommendedCities: Array
});

const form = useForm({
    search: "",
    location: localStorage.getItem('location') ?? '',
    date: null
});

const categories = [
    "Friseur",
    "Nagelstudio",
    "Enthaarungssalon",
    "Schönheitssalon",
    "Barber",
];

const locationHints = ref(props.recommendedCities ?? [])
const store = useSearchStore()

function search() {
    if (form.location.length === 0) {
        form.setError('location', __('Please select location'))
        return
    }

    localStorage.setItem('location', form.location)

    store.$patch({
        search: form.search,
        date: form.date,
        location: form.location
    })

    router.get(route("search", {city: form.location, category: form.search, date: form.date}));
}

function selectSearch(category) {
    form.search = category;
    closeHints()
}

function selectLocationHint(location) {
    form.location = location;
    showLocationHints.value = false
}

const showHints = ref(false);
const showLocationHints = ref(false);
const currentLocationLoading = ref(false);

function closeHints() {
    showHints.value = false;
}

function closeLocationHints() {
    showLocationHints.value = false;
}

function locatorButtonPressed() {
    currentLocationLoading.value = true

    if (!navigator.geolocation) {
        alert(__("Geolocation is not supported by this browser."))
    }

    navigator.geolocation.getCurrentPosition(
        (position) => {
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);

            getAddress(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
            console.log(error.message);

            form.setError('location', __(error.message))
        }
    );
}

async function getAddress(lat, long) {
    const resp = await axios.get(route('api.city.coordinates', {latitude: lat, longitude: long}));

    form.location = resp.data.city ?? resp.data.town ?? resp.data.village ?? '';
    showLocationHints.value = false
    currentLocationLoading.value = false
}

watch(() => form.location, (newVal) => {
    if (!newVal.length) {
        localStorage.removeItem('location')
    }

    if (form.errors.location && form.location.length) {
        form.errors.location = null
    }

    if (newVal.trim().length > 1) {
        axios.get(route('api.city.search', {
            _query: {
                name: newVal
            }
        }))
            .then(response => {
                locationHints.value = response.data.map(city => city.name)
            })
    }
})

</script>

<template>
    <form class="card shadow-sm rounded-3 position-relative p-4 pe-md-5 pb-5 pb-md-4">
        <div class="row g-4 align-items-center">
            <div class="col-lg-4 relative">
                <div class="form-control-border form-control-transparent form-fs-md d-flex">
                    <div class="w-100 relative">
                        <div class="absolute top-3 left-3">
                            <SearchIcon class="w-5 h-5"/>
                        </div>
                        <input type="search" class="form-control filled pl-10 h-12"
                               :placeholder="__('What are you looking for?')"
                               v-model="form.search" @click="showHints = true"/>
                    </div>
                </div>

                <div class="relative">
                    <ul class="mt-2 dropdown-menu input-dropdown shadow rounded w-100" :class="{ 'block': showHints }"
                        v-on-click-outside="closeHints" v-if="showHints">
                        <li v-for="c in categories" class="" :key="c" @click.prevent="selectSearch(c)">
                            <a class="dropdown-item" href="#"> {{ c }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Location -->
            <div class="col-lg-4 relative">
                <div class="form-control-border form-control-transparent form-fs-md relative">
                    <div class="absolute top-3 left-3">
                        <MapPinIcon class="w-5 h-5" @click="locatorButtonPressed"/>
                    </div>

                    <input type="search" class="form-control filled pl-10 h-12" placeholder="Aktueller Standort"
                           v-model="form.location" @click="showLocationHints = true"/>
                    <div v-if="form.errors.location" class="absolute text-red-600 font-xs">
                        {{
                            form.errors.location
                        }}
                    </div>
                    <div v-if="false">
                        <p class="">Sie haben keinen Zugriff auf Ihren aktuellen Standort erlaubt. Für die genauesten
                            Suchergebnisse passen Sie bitte die Standorteinstellungen an.</p>
                    </div>
                    <div class="relative" v-if="showLocationHints">
                        <ul class="mt-2 dropdown-menu input-dropdown shadow rounded w-100"
                            :class="{ 'block': showLocationHints }" v-on-click-outside="closeLocationHints"
                            v-if="showLocationHints">
                            <li>
                                <a class="dropdown-item flex items-center" href="#"
                                   @click.prevent="locatorButtonPressed">
                                    <MapPinIcon class="w-5 h-5 mr-2"/>
                                    {{ __('current location') }}

                                    <span class="clearfix">
                                        <span v-if="currentLocationLoading" class="ms-5 spinner-border spinner-border-sm float-end" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                    </span>
                                </a>
                            </li>
                            <li v-for="c in locationHints" class="" :key="c" @click.prevent="selectLocationHint(c)">
                                <a class="dropdown-item" href="#"> {{ c }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- date -->
            <div class="col-lg-4">
                <div class="relative">
                    <div class="absolute top-3 left-3">
                        <CalendarDaysIcon class="w-5 h-5"/>
                    </div>
                    <div class="form-control-border form-control-transparent form-fs-md">
                        <!-- <input type="text" class="form-control filled pl-10 h-12" placeholder="Aktueller Datum"
                            /> -->

                        <VueFlatpickr v-model="form.date"/>
                        <div v-if="form.errors.date" class="text-red-600 font-xs">{{ form.errors.date }}</div>
                        <!-- <DatePicker /> -->
                        <!-- <input type="date" class="form-control" placeholder="When" v-model="form.date" /> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Button -->
        <div class="btn-position-md-middle">
            <a class="icon-lg btn btn-round btn-primary mb-0" href="#" @click.prevent="search">
                <SearchIcon class="w-6 h-6"/>
            </a>
        </div>
    </form>
</template>
