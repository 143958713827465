<script setup>
import CookiesBanner from "@/Components/Welcome/CookiesBanner.vue";
import SearchFormWelcome from "@/Components/Welcome/SearchFormWelcome.vue";
import { Head } from "@inertiajs/vue3";
import VueTypedJs from "@/Components/VueTypedJs/VueTypedJs.vue"
import RecommendedCompanies from "@/Components/Welcome/RecommendedCompanies.vue";
import MetaTags from "@/Components/MetaTags.vue";
import AFooter from "@/Components/Layout/AFooter.vue";
import FooterWelcome from "@/Components/Layout/FooterWelcome.vue";

defineProps({
    recommendedCities: Array,
    recommendedCompanies: Object,
    metaTags: Object
});

const cityImgMap = {
    'Berlin': 'Berlin.jpg',
    'Hamburg': 'Hamburg.webp',
    'München': 'münchen.jpg',
    "Köln": "Köln.jpg",
    "Frankfurt am Main": "Frankfurt am Main.webp",
    "Stuttgart": 'Stuttgart.jpeg',
    "Düsseldorf": 'Düsseldorf.jpg',
    "Leipzig": "Leipzig.jpg"
}
</script>

<template>
    <Head :title="__('Homepage')" />
    <MetaTags :meta-tags="metaTags" />

    <section class="pt-3 pt-lg-5">
        <div class="container">
            <!-- Content and Image START -->
            <div class="row g-4 g-lg-5">
                <!-- Content -->
                <div class="col-lg-6 position-relative mb-4 mb-md-0">

                    <!-- Title -->
                    <h1 class="mb-4 mt-md-5 display-5">
                        Finde besten

                        <span class="position-relative z-index-9">
                            <vue-typed-js :strings="['Friseur', 'Nagelstudio', 'Kosmetikerin', 'Barbier']" :loop="true">
                                <h3 class="typing"></h3>
                            </vue-typed-js>
                            in der Nähe.
                            <!-- SVG START -->
                            <span class="position-absolute translate-middle z-index-n1 d-none d-md-block mt-4"
                                style="top: 57%; left: -225px">
                                <svg width="390px" height="21px" viewBox="0 0 445.5 21.5">
                                    <path class="fill-primary opacity-7"
                                        d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z">
                                    </path>
                                </svg>
                            </span>
                            <!-- SVG END -->
                        </span>
                    </h1>
                    <!-- Info -->
                    <p class="mb-4">
                        Vereinbare Termine in Sekundenschnelle egal wo du gerade bist.
                    </p>
                </div>

                <!-- Image -->
                <div class="col-lg-6 position-relative d-none d-md-block">
                    <img src="/images/bg/06.jpg" class="rounded" alt="" />
                </div>
            </div>
            <!-- Content and Image END -->

            <!-- Search START -->
            <div class="row" id="searchRow">
                <div class="col-xl-10 position-relative mt-n3 mt-xl-n9">
                    <SearchFormWelcome :recommendedCities="recommendedCities" />
                </div>
            </div>
            <!-- Search END -->
        </div>
    </section>

    <section class="pb80 bgc-white">
        <div class="container">
            <div class="row align-items-center wow fadeInUp" data-wow-delay="100ms"
                style="visibility: visible; animation-delay: 100ms; animation-name: fadeInUp;">
                <div class="col-lg-9">
                    <div class="main-title2">
                        <h2 class="title">Beliebte Städte</h2>
                        <p class="paragraph">Entdecken Sie die besten Unternehmen in der Nähe</p>
                    </div>
                </div>
                <div class="col-lg-3">
<!--                    <div class="text-start text-lg-end mb-3">-->
<!--                        <a class="ud-btn2" href="">Alle Städte anzeigen </a>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="row g-4 g-md-5">
                <!-- Card item START -->
                <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="city in recommendedCities">
                    <Link :href="route('search', city)">
                    <div class="card bg-transparent text-center p-1 h-100">
                        <!-- Image -->
                        <img :src="`/images/city/${cityImgMap[city]}`" class="rounded-circle" alt=""
                            style="aspect-ratio: 1 / 1;">

                        <div class="card-body p-0 pt-3">
                            <h5 class="card-title"><a href="#" class="stretched-link">{{ city }}</a></h5>
<!--                             <span>x companies</span>-->
                        </div>
                    </div>
                    </Link>

                </div>
                <!-- Card item END -->

            </div>
        </div>
    </section>

    <section>
        <div class="container">
        <RecommendedCompanies :recommended-companies="recommendedCompanies" />
        </div>
    </section>

    <section class="bg-light">
        <div class="container">
            <div class="row g-4">

                <!-- Help -->
                <div class="col-md-6 col-xxl-4">
                    <div class="bg-body d-flex rounded-3 h-100 p-4">
                        <h3><i class="fa-solid fa-hand-holding-heart"></i></h3>
                        <div class="ms-3">
                            <h5>24x7 Help</h5>
                            <p class="mb-0">If we fall short of your expectation in any way, let us know</p>
                        </div>
                    </div>
                </div>

                <!-- Trust -->
                <div class="col-md-6 col-xxl-4">
                    <div class="bg-body d-flex rounded-3 h-100 p-4">
                        <h3><i class="fa-solid fa-hand-holding-usd"></i></h3>
                        <div class="ms-3">
                            <h5>Payment Trust</h5>
                            <p class="mb-0">All refunds come with no questions asked guarantee</p>
                        </div>
                    </div>
                </div>

                <!-- Download app -->
<!--                <div class="col-lg-6 col-xl-5 col-xxl-3 ms-xxl-auto">-->
<!--                    <h5 class="mb-4">Download app</h5>-->
<!--                    <div class="row g-3">-->
<!--                        &lt;!&ndash; Google play store button &ndash;&gt;-->
<!--                        <div class="col-6 col-sm-4 col-md-3 col-lg-6">-->
<!--                            <a href="#"> <img src="/images/google-play.svg" alt=""> </a>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; App store button &ndash;&gt;-->
<!--                        <div class="col-6 col-sm-4 col-md-3 col-lg-6">-->
<!--                            <a href="#"> <img src="/images/app-store.svg" alt=""> </a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
        </div>
    </section>

    <FooterWelcome />
    <CookiesBanner />
</template>

<style></style>
