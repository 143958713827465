<script setup>
import { onMounted, ref } from 'vue';

const accepted = ref(false)

onMounted(() => {
    accepted.value = JSON.parse(localStorage.getItem('cookiesAccepted'))
})

function acceptCookies() {
    localStorage.setItem('cookiesAccepted', JSON.stringify(true))
    accepted.value = true
}
</script>

<template>
    <div class="fixed bottom-0 left-0 w-100 z-50 border-t py-3 bg-white" v-if="!accepted">
        <div class="flex justify-center w-100" style="width: 1024px;">
            <div class="flex space-x-4">
                <span class="">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="h-6 w-6">
                        <path
                            d="M28 15.926a11.962 11.962 0 0 1-3.522 8.567c-4.619 4.61-12.164 4.662-16.842.112a12 12 0 0 1 8.436-20.604 1.001 1.001 0 0 1 .976 1.22 4 4 0 0 0 3.857 4.879 1.003 1.003 0 0 1 .995.995 3.999 3.999 0 0 0 4.88 3.857 1.002 1.002 0 0 1 1.22.974v0Z"
                            stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                            d="M19.5 23a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11.5 22a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM10.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM17 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                            fill="#000"></path>
                    </svg>
                </span>
                <div class="block">
                    <p class="m-0">
                        Wir verwenden Cookies, um Ihre Erfahrung zu verbessern und den Website-Verkehr zu
                        überwachen.
                    </p>
                    <p class="m-0">
                        <a href="/cookies" target="_blank" class="">
                            <span class="">Cookie-Richtlinien</span>
                        </a>
                    </p>
                </div>

                <div>
                    <button class="btn btn-light" @click="acceptCookies">
                        Annehmen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
