<script setup>
import CompanyCard from './CompanyCard.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

defineProps({
    recommendedCompanies: Object
})
const breakpoints = {
    350: {
        itemsToShow: 1,
    },
    // 700px and up
    700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
    },
    // 1024 and up
    1024: {
        itemsToShow: 3.5,
        snapAlign: 'center',
    },
}

function onSlideEnd(event) {
    // event.preventDefault()
}
</script>

<template>

    <section class="layout-pt-60 layout-pb-60">
        <div class="auto-container">
            <div class="row justify-content-between align-items-end">
                <div class="col-lg-12">
                    <div class="sec-title mb-0">
                        <h2 class="color-blue-dark fw-600">Empfohlene Unternehmen</h2>
                        <div class="text">
                            Unternehmen, die sich laut unserem Portal von den anderen abheben.
                        </div>
                    </div>
                </div>

<!--                <div class="col-auto">-->
<!--                    <Link :href="route('companies.index')" class="button -arrow text-dark-blue">-->
<!--                        {{ __('More') }}-->
<!--                        <span class="fa fa-angle-right"></span>-->
<!--                    </Link>-->
<!--                </div>-->
            </div>

            <div class="carousel-outer pt-50 wow fadeInUp">
                <carousel :items-to-show="3.5" :wrapAround="true" @slide-end="onSlideEnd($event)" :touchDrag="false"
                          :breakpoints="breakpoints" :autoplay="4500">
                    <slide v-for="company in recommendedCompanies" :key="company.slug">
                        <CompanyCard :company="company" />
                    </slide>

                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </section>

</template>

<style>
.company-card {
    min-height: 355px;
}

.carousel__item {
    min-height: 365px;
    width: 100%;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 2px solid #232323;
    background: white;
}
</style>
