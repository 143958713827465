<script setup>
import Typed from 'typed.js';
import {onMounted, ref} from "vue";

const props = defineProps({
  strings: Array,
  typeSpeed: {
    type: Number,
    default: 100,
  },
  backSpeed: {
    type: Number,
    default: 60
  },
  loop: {
    type: Boolean,
    default: true
  },
})

const typing = ref(null);

onMounted(() => {
  new Typed(typing.value, {
    strings: props.strings,
    typeSpeed: props.typeSpeed,
    backSpeed: props.backSpeed,
    loop: props.loop
  });
});

</script>

<template>
    <div class="typed-element" ref="typedElement">
      <span ref="typing"><slot /> </span>
    </div>
</template>

<style>
.typed-element {
    display: flex;
    align-items: center;
}

.typed-element .typed-cursor {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
    50% {
        opacity: 0.0;
    }
}
</style>
